





















import { Vue, Component, Prop } from "vue-property-decorator";

export type ModalAssetDataView = {
  brand: string;
  type: string;
  serialNumber: string;
};

@Component
export default class ModalAsset extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  visible!: boolean;

  @Prop({ required: true, type: Object })
  dataView!: ModalAssetDataView;

  handleOk(): void {
    this.$emit("ok");
  }
}
