














































































































































































































import { SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import { debounceProcess } from "@/helpers/debounce";
import {
  Row,
  useAsset,
  useBlob,
  useDate,
  useLocalFilter,
  useLocation,
  useProduct,
  useStockReport,
  useWarehouse
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS as DEFAULT_PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Nullable } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { WarehouseLocationResponseDto } from "@/models/interface/location";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  ProductDetailDto,
  ProductResponseDto,
  ProductUomConversionDto
} from "@/models/interface/master-product";
import {
  StockReportDownloadParams,
  StockReportParams,
} from "@/models/interface/stock-report";
import {
  StockCardReportResponseDto,
  StockCardReportResultDto
} from "@/models/interface/StockReport.interface";
import { WarehouseResponseDto } from "@/models/interface/warehouse";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";
import ModalAsset, { ModalAssetDataView } from "./ModalAsset.vue";

type FormFilter = Nullable<{
  productCode: string;
  productName: string;
  productId: string;
  uomId: string;
  uom: string;
  branch: string;
  branchId: string;
  warehouse: string;
  warehouseId: string;
  location: string;
  locationId: string;
  date: Moment[];
}>;
type TableRow = Row<StockCardReportResponseDto, number>;

@Component({
  components: {
    ModalAsset,
    SelectProduct,
    SelectBranch,
  },
})
export default class IndexPage extends Mixins(MNotificationVue) {
  @Ref("form") form!: FormModel;

  useLocalFilter = useLocalFilter;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS, "0"];

  modal = {
    show: false,
    data: {
      brand: "",
      serialNumber: "",
      type: "",
    } as ModalAssetDataView,
    toggle(): void {
      this.show = !this.show;
    },
  };

  formModel: FormFilter = {
    productCode: "",
    productName: "",
    productId: "",
    uomId: "",
    uom: "",
    branch: "",
    branchId: "",
    warehouse: "",
    warehouseId: "",
    location: "",
    locationId: "",
    date: null,
  };

  formRules = {
    productCode: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    productName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    uomId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    branch: [],
    warehouse: [],
    location: [],
    date: [],
  };

  optUom: Option<ProductUomConversionDto>[] = [];
  optWarehouse: Option<WarehouseResponseDto>[] = [];
  optLocation: Option<WarehouseLocationResponseDto>[] = []; // rack options

  loading = {
    download: false,
    location: false,
    uom: false,
    warehouse: false,
    find: false,
  };

  columns = [
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      key: "documentReference",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_document_relation"),
      dataIndex: "documentRelated",
      key: "documentRelated",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_supplier_name"),
      dataIndex: "customerSupplierName",
      key: "customerSupplierName",
      width: 300,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      scopedSlots: { customRender: "detail" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "unitSerialNumber",
      key: "unitSerialNumber",
      scopedSlots: { customRender: "detail" },
    },
    {
      title: this.$t("lbl_qty_in"),
      dataIndex: "qtyIn",
      key: "qtyIn",
      width: "100px",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_out"),
      dataIndex: "qtyOut",
      key: "qtyOut",
      width: "100px",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_balance"),
      dataIndex: "qtyBalance",
      key: "qtyBalance",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "notes",
      width: 250,
      key: "notes",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      scopedSlots: { customRender: "nullable" },
    },
  ];

  dataReport: StockCardReportResultDto = {
    beginningBalance: 0,
    endingBalance: 0,
    totalQtyIn: 0,
    totalQtyOut: 0,
    data: [],
  };

  created(): void {
    this.onSearchWarehouse = debounceProcess(this.onSearchWarehouse);
    this.onSearchLocation = debounceProcess(this.onSearchLocation);
  }

  findStockReport(): void {
    this.fetchStockReport(this.buildSearch());
  }

  fetchStockReport(params: StockReportParams): void {
    const { findAllV2 } = useStockReport();
    this.loading.find = true;
    findAllV2(params)
      .then(res => {
        this.dataReport = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onChangeProduct(option?: Option<ProductResponseDto>): void {
    this.formModel.productId = option?.meta?.id || "";
    this.formModel.productName = option?.meta?.name || "";
    this.formModel.productCode = option?.meta?.code || "";
    this.formModel.uomId = "";
    this.formModel.uom = "";

    this.fetchUom(this.formModel.productId, () => {
      this.formModel.uomId = this.optUom[0]?.value || "";
      this.formModel.uom = this.optUom[0]?.label || "";
    });
  }

  fetchUom(productId: string, cb: (payload: ProductDetailDto) => void): void {
    const { findById, toOptionsUomConv } = useProduct();

    this.loading.uom = true;
    findById(productId)
      .then((res: ProductDetailDto) => {
        this.optUom = toOptionsUomConv(res.uomConversions);

        cb(res);
      })
      .finally(() => {
        this.loading.uom = false;
      });
  }

  onSearchWarehouse(search = ""): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const q: string[] = [];

    if (this.formModel.branchId) {
      q.push(
        builder
          .push(["branchWarehouse.secureId", this.formModel.branchId])
          .build()
      );
    }

    if (search) {
      q.unshift(builder.push(["name", search], { like: "both" }).build());
    }

    params.search = q.join(builder.AND);

    this.fetchWarehouse(params);
  }

  fetchWarehouse(params: RequestQueryParams): void {
    const { findAll } = useWarehouse();
    this.loading.warehouse = true;
    findAll(params)
      .then(res => {
        this.optWarehouse = res.data.map<Option<WarehouseResponseDto>>(
          item => ({
            label: item.name,
            value: item.name,
            key: item.id,
            meta: item,
          })
        );
      })
      .finally(() => {
        this.loading.warehouse = false;
      });
  }

  onChangeBranch(value: Option<DataWarehouseBranch> | undefined): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    this.formModel.branch = value?.label || "";
    this.formModel.branchId = value?.meta?.id || "";

    if (value) {
      params.search = builder
        .push(["branchWarehouse.secureId", this.formModel.branchId])
        .build();
    }

    this.formModel.warehouse = null;
    this.formModel.warehouseId = null;
    this.formModel.location = null;
    this.formModel.locationId = null;
    this.fetchWarehouse(params);
  }

  fetchLocation(params: RequestQueryParams): void {
    const { findAll, toOptions } = useLocation();

    this.loading.location = true;
    findAll(params)
      .then(res => {
        this.optLocation = toOptions(res.data);
      })
      .finally(() => {
        this.loading.location = false;
      });
  }

  onChangeWarehouse(value: string | null): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const option = this.optWarehouse.find(item => item.value === value);

    this.formModel.warehouseId = option?.meta?.id || "";

    if (this.formModel.warehouseId) {
      params.search = builder
        .push(["warehouse.secureId", this.formModel.warehouseId])
        .build();
    }

    this.formModel.location = null;
    this.formModel.locationId = null;
    this.fetchLocation(params);
  }

  onSearchLocation(search = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (this.formModel.warehouseId) {
      q.push(
        builder.push(["warehouse.secureId", this.formModel.warehouseId]).build()
      );
    }

    if (search) {
      q.unshift(builder.push(["name", search], { like: "both" }).build());
    }

    params.search = q.join(builder.AND);
    this.fetchLocation(params);
  }

  handleReset(): void {
    this.form.resetFields();
    this.formModel.branchId = "";
    this.formModel.locationId = "";
    this.formModel.warehouseId = "";
    this.formModel.productId = "";
    this.formModel.date = [];
  }

  onSizeChange(current: number, size: number): void {
    this.pagination.page = FIRST_PAGE;
    this.pagination.limit = size === 0 ? this.dataReport.data.length : size;
  }

  onChangePage(page: number, pageSize: number): void {
    this.pagination.page = page;
    this.pagination.limit = pageSize;
  }

  handleSubmit(): void {
    this.form.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifError("notif_validation_error");
        return;
      }

      this.findStockReport();
    });
  }

  onChangeLocation(value: string): void {
    const option = this.optLocation.find(e => e.value === value);
    this.formModel.locationId = option?.meta?.id || "";
  }

  buildSearch(): StockReportParams {
    const { toStartDay, toEndDay } = useDate();

    const params: StockReportParams = {};
    if (this.formModel.branchId) {
      params.branchId = this.formModel.branchId;
    }

    if (this.formModel.warehouseId) {
      params.warehouseId = this.formModel.warehouseId;
    }

    if (this.formModel.date && this.formModel.date.length) {
      const [start, end] = this.formModel.date;
      params.startDate = toStartDay(start).format();
      params.endDate = toEndDay(end).format();
    }

    if (this.formModel.locationId) {
      params.locationId = this.formModel.locationId;
    }

    if (this.formModel.productId) {
      params.productId = this.formModel.productId;
    }

    if (this.formModel.uomId) {
      params.uomId = this.formModel.uomId;
    }

    return params;
  }

  handleDownload(): void {
    const { downloadV2, buildParamsDownload } = useStockReport();
    const { toDownload } = useBlob();
    const params: StockReportDownloadParams = {
      ...this.buildSearch(),
      params: buildParamsDownload({
        branchName: this.formModel.branch || "",
        warehouseName: this.formModel.warehouse || "",
        locationName: this.formModel.location || "",
        productCode: this.formModel.productCode || "",
        productName: this.formModel.productName || "",
        uom: this.formModel.uom || "",
        date: this.formModel.date || [],
      }),
    };

    this.loading.download = true;
    downloadV2(params)
      .then(res => {
        toDownload(res, "stock_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChangeUom(value: string): void {
    const option = this.optUom.find(e => e.value === value);
    this.formModel.uom = option?.label || "";
  }

  showAsset(row: TableRow): void {
    const { findAllAsset } = useAsset();
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();

    params.search = builder
      .push(["unitCode", row.unitCode])
      .or()
      .push(["serialNumber", row.unitSerialNumber])
      .build();

    findAllAsset(params).then(res => {
      const [item] = res.data;

      this.modal.data = {
        brand: item?.assetCategory.segments[1]?.first || "",
        type: item?.type || "",
        serialNumber: item?.serialNumber || "",
      };
      this.modal.toggle();
    });
  }
}
