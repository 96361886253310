var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        width: "50%",
        title: _vm.$t("lbl_unit_code")
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-descriptions",
        { attrs: { size: "small" } },
        [
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_serial_number") } },
            [_vm._v(" " + _vm._s(_vm.dataView.serialNumber || "-") + " ")]
          ),
          _c("a-descriptions-item", { attrs: { label: _vm.$t("lbl_brand") } }, [
            _vm._v(" " + _vm._s(_vm.dataView.brand || "-") + " ")
          ]),
          _c("a-descriptions-item", { attrs: { label: _vm.$t("lbl_type") } }, [
            _vm._v(" " + _vm._s(_vm.dataView.type || "-") + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }